
@media screen and (max-width: 639px) {
    .Navigation {
        z-index: 2;
        height: 10vh;
        max-height: 3rem;
        width: 100%;
    }

    .BurgerNavMenu {
        max-width: 40px;
    }

    .BurgerNavMenu > div {
        min-width: 100vw;
        opacity: 0.85;
    }
}

@media screen and (min-width: 640px) {
    .Navigation {
        @apply bg-gray-900 text-right min-w-min;
        height: 100vh;
        display: flex;
        flex-direction: column;
    }

    .BurgerNavMenu {
        order: 99;
    }
    .BurgerNavMenu > button {
        display: none;
    }
    .BurgerNavMenu > div {
        display: flex;
    }

}

