.FetchesList {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}
.Fetch {
    text-align: center;
    margin: 0.5rem;
    padding: 0.5rem;
    display: block;
    min-width: 17.5rem;
    width: 100%;
    max-width: 22.5rem;
    height: 7.5rem;
    background-color: var(--background-slight-offset);
    box-shadow: var(--shadow-main);
    border-radius: 0.25rem;
    position: relative;
}
.Fetch.default {
    background-color: var(--background-slight-offset);
}
.Fetch.success {
    background-color: var(--background-dark-green);
}
.Fetch.error {
    background-color: var(--background-dark-red);
}
.Fetch.warning {
    background-color: var(--background-dark-yellow);
}
.Fetch > .header {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2em;
    padding-bottom: 0.5rem;
}
.Fetch > .footer {
    position: absolute;
    display: flex;
    justify-content: space-between;
    width: calc(100% - 1rem);
    bottom: 0.5rem;
    color: var(--font-slight-offset);
    font-size: 0.8em;
}
