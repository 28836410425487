:root {
  --background-slight-offset-seethrough: rgba(65, 60, 60, 0.5);
  --background-slight-offset: #413c3c;
  --background-main: #1f2228;
  --background-dark-blue: #10107a;
  --background-dark-green: #004524;
  --background-dark-red: #540000;
  --background-dark-yellow: #6e5124;
  --font-main: aliceblue;
  --font-slight-offset: rgba(225, 225, 225, 0.8);
  --font-larger: 1.25rem;
  --font-smaller: 0.85rem;
  --shadow-main: none;
  --border-radius-small: 0.125rem;
  --border-radius-default: 0.25rem;
  --border-radius-strong: 0.5rem;
  --padding-small: 0.25rem;
  --padding-default: 0.5rem;
  --padding-strong: 1rem;
  --margin-strong: 1rem;
  --margin-default: 0.5rem;
  --margin-small: 0.25rem;
  --outline-border: 1px solid black;
  /*  --shadow-main: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;*/
}

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--background-main);
  color: var(--font-main);
}

.slight-offset-color {
  color: var(--font-slight-offset);
}

.font-smaller {
  font-size: var(--font-smaller);
}

.font-larger {
  font-size: var(--font-larger);
}

a {
  color: var(--font-main);
}

@media only screen and (max-width: 639px) {
  #root {
    display: flex;
    max-height: 100vh;
    flex-direction: column;
  }
  /*#root > div {
    flex-grow: 1;
    flex-shrink: 1;
    overflow-y: auto;
    max-height: calc(100vh - 3rem);
  }*/
}

@media only screen and (min-width: 640px) {
  #root {
    display: flex;
    max-height: 100vh;
  }
  /*
    #root > div {
      flex-grow: 1;
      flex-shrink: 1;
      overflow-y: auto;
    }*/
}

.btn {
  margin: var(--margin-small);
  display: inline-block;
  padding: 0.25rem 0.75rem;
  border-radius: 0.25rem;
  user-select: none;
  background-color: var(--background-dark-blue);
  color: var(--font-main);
  border: none;
}

.btn:disabled,
.btn.disabled {
  background-color: var(--background-slight-offset-seethrough);
  border: var(--outline-border);
  opacity: 80%;
  @apply disabled:cursor-not-allowed;
}

button:disabled {
  @apply disabled:cursor-not-allowed;
}

@media (pointer: fine) {
  /* width */
  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: rgba(241, 241, 241, 0.2);
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

.card {
  border-radius: var(--border-radius-strong);
  background-color: var(--background-slight-offset);
  padding: var(--padding-strong) var(--padding-strong) var(--padding-default);
}

.card.success-background {
  background-color: var(--background-dark-green);
}

.card.error-background {
  background-color: var(--background-dark-red);
}

.card.warning-background {
  background-color: var(--background-dark-yellow);
}


.PageBody {
  @apply flex flex-col w-full;
  min-height: calc(100vh - 3rem);
  height: calc(100vh - 3rem);
}

.PageBody > .PageHeader {

}

.PageBody > .PageContent {
  @apply flex-grow overflow-y-auto;
}

.PageBody > .PageFooter {
  @apply flex justify-around py-1;
}

@media screen and (min-width: 640px) {
  .PageBody {
    min-height: 100vh;
    height: 100vh;
  }
}

article.ChapterBody p {
  padding-left: 5px;
}
